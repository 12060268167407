import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { AcesseSuaContaModalComponent } from 'src/app/shared/components/modais/acesse-sua-conta-modal/acesse-sua-conta-modal.component';
import { CetEspelhoModalComponent } from 'src/app/shared/components/modais/cet-espelho-modal/cet-espelho-modal.component';
import { LoadingModalComponent } from 'src/app/shared/components/modais/loading-modal/loading-modal.component';
import { AvisoGenericoModalComponent } from 'src/app/shared/components/modais/mensagens/aviso-generico-modal/aviso-generico-modal.component';
import { ErroGenericoModalComponent } from 'src/app/shared/components/modais/mensagens/erro-generico-modal/erro-generico-modal.component';
import { SucessoModalComponent } from 'src/app/shared/components/modais/mensagens/sucesso-modal/sucesso-modal.component';
import { BlurService } from './blur.service';
import { LoadingService } from './loading.service';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  constructor(
    private modal: MatDialog,
    private spinner: NgxSpinnerService,
    private _blurService: BlurService,
    private _loadingService: LoadingService
  ) {}

  fecharTudo() {
    this.modal.closeAll();
    this._blurService.setBlurBackground(false);
  }

  exibirLoading() {
    this.modal.open(LoadingModalComponent);
  }

  fecharLoading() {
    this.spinner.hide();
    this._loadingService.setLoading(false);
    this._blurService.setBlurBackground(false);
  }

  abrirModalAcesseSuaConta() {
    this.modal.open(AcesseSuaContaModalComponent);
  }

  exibirErro(erro?: string) {
    this.fecharLoading();
    if (erro) {
      const modalConfig = new MatDialogConfig();
      modalConfig.data = { msg: erro };
      this.modal.open(ErroGenericoModalComponent, modalConfig);
    } else {
      this.modal.open(ErroGenericoModalComponent);
    }
  }

  exibirAviso(msg: string, urlParaNavegarAoFecharModal?: string) {
    this.fecharLoading();
    if (msg) {
      const modalConfig = new MatDialogConfig();
      modalConfig.data = { msg: msg };
      var modal = this.modal.open(AvisoGenericoModalComponent, modalConfig);
      if (
        urlParaNavegarAoFecharModal != null &&
        urlParaNavegarAoFecharModal != ''
      ) {
        modal.afterClosed().subscribe(() => {
          this._blurService.setBlurBackground(false);
          this.modal.closeAll();
          window.open(urlParaNavegarAoFecharModal, '_self');
        });
      }
      else{
        modal.afterClosed().subscribe(() => {
          this._blurService.setBlurBackground(false);
          this.modal.closeAll();
        });
      }
    } else {
      var modal = this.modal.open(AvisoGenericoModalComponent);
      if (
        urlParaNavegarAoFecharModal != null &&
        urlParaNavegarAoFecharModal != ''
      ) {
        modal.afterClosed().subscribe(() => {
          this._blurService.setBlurBackground(false);
          this.modal.closeAll();
          window.open(urlParaNavegarAoFecharModal, '_self');
        });
      }
    }
  }

  exibirSucesso(msg?: string, reopenPage?: boolean) {
    this.fecharLoading();

    if (msg) {
      const modalConfig = new MatDialogConfig();
      modalConfig.data = { msg: msg };
      var modal = this.modal.open(SucessoModalComponent, modalConfig);

      if (reopenPage) {
        modal.afterClosed().subscribe(() => {
          this._blurService.setBlurBackground(false);
          this.modal.closeAll();
          window.open(window.location.href, '_self');
        });
      }

    } else {
      var modal = this.modal.open(SucessoModalComponent);

      if (reopenPage) {
        modal.afterClosed().subscribe(() => {
          this._blurService.setBlurBackground(false);
          this.modal.closeAll();
          window.open(window.location.href, '_self');
        });
      }
    }
  }

  abrirModalCetEspelho(signatureUrl: string, nomeArquivo: string, tipoRelatorio: number): void {
    this.fecharLoading();
    this.modal.open(CetEspelhoModalComponent, {
      data: {
        signatureUrl: signatureUrl,
        signatureNomeArquivo: nomeArquivo,
        signatureTipoRelatorio: tipoRelatorio
      }
    });
  }
}
