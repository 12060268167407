import { environment } from "./environment";

export const urlApiIntegracaoInvestimento = environment.urlApiIntegracaoInvestimento
export const urlApiSac = environment.urlApiSac
export const urlApiPesquisaSatisfacao = environment.urlApiPesquisaSatisfacao
export const urlApiInstitucional = environment.urlApiInstitucional
export const urlApiInformeRendimentos = environment.urlApiInformeRendimentos
export const urlApiInstitucionalWebDmz = environment.urlApiInstitucionalWebDmz
export const urlApiLead = environment.urlApiLead
export const urlDominioSite = environment.urlDominioSite
export const urlApiPrognum = environment.urlApiPrognum
export const urlDaycovalSeguros = environment.urlDaycovalSeguros
